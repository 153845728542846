import React from 'react';

const GaleriaModal = (props) => {
  const handleOpenFullScreen = () => {
    let elem = document.getElementById(props.id);
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari & Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen();
    }
  };

  let next = `#${props.id + 1}`;
  let prev = `#${props.id - 1}`;
  let close = '#';

  if (props.id === 0) {
    prev = '#11';
  } else if (props.id === 11) {
    next = '#0';
  }

  return (
    <article className='light-box' id={props.id}>
      <span className='titulo'>{props.title}</span>
      <a href={prev} className='arrow prev'>
        <span className='fa-stack fa-lg'>
          <i className='fa fa-circle-thin fa-stack-2x'></i>
          <i className='fa fa-angle-left fa-stack-1x'></i>
        </span>
      </a>
      <img src={props.img} alt={props.title} />
      <a href={next} className='arrow next'>
        <span className='fa-stack fa-lg'>
          <i className='fa fa-circle-thin fa-stack-2x'></i>
          <i className='fa fa-angle-right fa-stack-1x'></i>
        </span>
      </a>
      <a href={close} className='close'>
        <div></div>
      </a>
      <span
        className='full'
        title='fullscreen'
        onClick={() => handleOpenFullScreen()}>
        <i className='fa fa-desktop' aria-hidden='true'></i>
      </span>
    </article>
  );
};

export default GaleriaModal;
