import React from 'react';

import Footer from './Footer';
import GaleriaModal from './GaleriaModal';
import GaleriaItem from './GaleriaItem';

import '../css/galeria.css';

import im1 from '../assets/Galeria/1.jpg';
import im2 from '../assets/Galeria/2.jpg';
import im3 from '../assets/Galeria/3.jpg';
import im4 from '../assets/Galeria/4.jpg';
import im5 from '../assets/Galeria/5.jpg';
import im6 from '../assets/Galeria/6.jpg';
import im7 from '../assets/Galeria/7.jpg';
import im8 from '../assets/Galeria/8.jpg';
import im9 from '../assets/Galeria/9.jpg';
import im10 from '../assets/Galeria/10.jpg';
import im11 from '../assets/Galeria/11.jpg';
import im12 from '../assets/Galeria/12.jpg';

const Galeria = () => {
  let datos = [
    { title: 'Desinfección en buses', img: im1 },
    { title: 'Desinfección en buses', img: im2 },
    { title: 'Desinfección en vivienda', img: im3 },
    { title: 'Fumigación en departamentos', img: im4 },
    { title: 'Fumigación en industrias', img: im5 },
    { title: 'Fumigación en industrias', img: im6 },
    { title: 'Fumigación en mercados', img: im7 },
    { title: 'Fumigación en mercados', img: im8 },
    { title: 'Fumigación en comercios', img: im9 },
    { title: 'Fumigación en obras', img: im10 },
    { title: 'Fumigación - Pulverización', img: im11 },
    { title: 'Fumigación - viviendas', img: im12 },
  ];

  const galeria = datos.map((item, index) => {
    return (
      <GaleriaItem key={index} id={index} title={item.title} img={item.img} />
    );
  });

  const modals = datos.map((item, index) => {
    return (
      <GaleriaModal key={index} id={index} title={item.title} img={item.img} />
    );
  });

  return (
    <>
      <div className='op-body-galeria'>
        <div className='op-modal'>{modals}</div>
        <div className='op-galeria'>{galeria}</div>
      </div>
      <Footer />
    </>
  );
};

export default Galeria;
