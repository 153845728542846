import React from 'react';
import { Link, NavLink } from 'react-router-dom';

import '../css/header.css';

const Header = () => {
  const handleHeader = (e) => {
    let element = e.target;
    element
      .closest('nav.op-header')
      .querySelector("input[type='checkbox'").checked = false;
  };

  return (
    <nav className='op-header'>
      <input type='checkbox' id='checkMenu'></input>
      <label htmlFor='checkMenu' className='checkButton'>
        <span></span>
        <span></span>
        <span></span>
      </label>
      <Link to='/' className='op-logo'></Link>
      <ul>
        <li>
          <NavLink
            to='/'
            //activeClassName='active'
            onClick={(e) => handleHeader(e)}>
            Inicio
          </NavLink>
        </li>
        <li>
          <NavLink
            to='/nosotros'
            //activeClassName='active'
            onClick={(e) => handleHeader(e)}>
            Nosotros
          </NavLink>
        </li>
        <li>
          <NavLink
            to='/servicio'
            //activeClassName='active'
            onClick={(e) => handleHeader(e)}>
            Servicios
          </NavLink>
        </li>
        <li>
          <NavLink
            to='/galeria'
            //activeClassName='active'
            onClick={(e) => handleHeader(e)}>
            Galer&iacute;a
          </NavLink>
        </li>
        <li>
          <NavLink
            to='/contactenos'
            //activeClassName='active'
            onClick={(e) => handleHeader(e)}>
            Cont&aacute;ctenos
          </NavLink>
        </li>
      </ul>
      <div className='op-redes'>
        <a
          href='https://wa.me/51947040968?text=Hola%20necesito%20informacion'
          target='_blank'
          rel='noopener noreferrer'>
          <i className='fa fa-whatsapp' aria-hidden='true'></i>
        </a>
        <a
          href='https://web.facebook.com/EMSAG.PERU/?_rdc=1&_rdr'
          target='_blank'
          rel='noopener noreferrer'
          className='facebook'>
          <div></div>
        </a>
      </div>
    </nav>
  );
};

export default Header;
