import React from 'react';

const Icono = (props) => {
  return (
    <div className='op-icon'>
      <div
        className='imagen'
        style={{ backgroundImage: `url(${props.img})` }}></div>
      <span>{props.title}</span>
    </div>
  );
};

export default Icono;
