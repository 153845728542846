import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Contacto from './components/Contacto';
import Galeria from './components/Galeria';
import Servicio from './components/Servicio';
import Nosotros from './components/Nosotros';
import Inicio from './components/Inicio';
import Header from './components/Header';

import './css/general.css';
import './css/icono.css';
const App = () => {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path='/contactenos' element={<Contacto />} />
        <Route path='/galeria' element={<Galeria />} />
        <Route path='/servicio' element={<Servicio />} />
        <Route path='/nosotros' element={<Nosotros />} />
        <Route path='/' element={<Inicio />} />
      </Routes>
      <a
        href='https://wa.me/51947040968?text=Hola%20necesito%20informacion'
        target='_blank'
        rel='noopener noreferrer'
        className='wasap-flotante'></a>
    </BrowserRouter>
  );
};

export default App;
