import React from 'react';

import ServicioSlide from './ServicioSlide';
import Footer from './Footer';

import i01 from '../assets/Servicios/icon-servicio_01.svg';
import i02 from '../assets/Servicios/icon-servicio-02.svg';
import i03 from '../assets/Servicios/icon-servicio-03.svg';
import i04 from '../assets/Servicios/icon-servicio-04.svg';
import i05 from '../assets/Servicios/icon-servicio-05.svg';
import i06 from '../assets/Servicios/icon-servicio-06.svg';

import m011 from '../assets/Servicios/1/1.jpeg';
import m012 from '../assets/Servicios/1/2.jpg';
import m013 from '../assets/Servicios/1/3.jpg';
import m014 from '../assets/Servicios/1/4.jpg';
import m021 from '../assets/Servicios/2/1.jpeg';
import m022 from '../assets/Servicios/2/2.jpg';
import m023 from '../assets/Servicios/2/3.jpg';
import m024 from '../assets/Servicios/2/4.jpg';
import m031 from '../assets/Servicios/3/1.jpeg';
import m032 from '../assets/Servicios/3/2.jpg';
import m033 from '../assets/Servicios/3/3.jpg';
import m034 from '../assets/Servicios/3/4.jpg';
import m041 from '../assets/Servicios/4/1.jpeg';
import m042 from '../assets/Servicios/4/2.jpg';
import m043 from '../assets/Servicios/4/3.jpg';
import m044 from '../assets/Servicios/4/4.jpg';
import m051 from '../assets/Servicios/5/1.jpg';
import m052 from '../assets/Servicios/5/2.jpg';
import m053 from '../assets/Servicios/5/3.jpg';
import m054 from '../assets/Servicios/5/4.jpg';
import m061 from '../assets/Servicios/6/1.jpeg';
import m062 from '../assets/Servicios/6/2.jpg';
import m063 from '../assets/Servicios/6/3.jpg';
import m064 from '../assets/Servicios/6/4.jpg';

import '../css/servicio.css';

const Servicio = () => {
  return (
    <>
      <div className='op-body-servicios'>
        <div className='op-section op-servicios'>
          <ServicioSlide
            src={m011}
            imgs={[m012, m013, m014]}
            emb={i01}
            title='Desratizaci&oacute;n'
            //subtitle="Control y eliminaci&oacute;n de roedores en industrias y empresas con estaciones profesionales."
          >
            El servicio de desratizaci&oacute;n permite disminuir la
            poblaci&oacute;n de roedores presentes en un lugar determinado. Con
            una serie de m&eacute;todos mediante las cuales se consiguen
            ahuyentar e eliminar plagas de ratas y ratones de un local, vivienda
            o cualquier otro ambiente.
            <br /> <br />
            El objetivo principal de la desratizaci&oacute;n ese el exterminio
            de animales roedores. Este tipo de animales son una fuente
            importante de transmisi&oacute;n de muchas enfermedades. Suele
            provocarse el aumento de roedores en aquellos lugares o sitios donde
            las condiciones higi&eacute;nicas b&aacute;sicas y del entorno no
            son adecuadas. La dificultad principal de esta situaci&oacute;n es
            que los roedores suelen tener gran adaptaci&oacute;n en diferentes
            entornos y h&aacute;bitats. Presentan un alto nivel para vivir.
            <br /> <br />
            Tipos de m&eacute;todos que se aplican en la desratizaci&oacute;n
            como:
            <ul>
              <li>M&eacute;todos f&iacute;sicos: Se suele usar trampas</li>
              <li>
                M&eacute;todos qu&iacute;micos: Con la utilizaci&oacute;n de
                sustancias toxicas
              </li>
            </ul>
          </ServicioSlide>
          <ServicioSlide
            src={m021}
            imgs={[m022, m023, m024]}
            emb={i03}
            title='Fumigaci&oacute;n o Desinsectaci&oacute;n'
            //subtitle="Uso de t&eacute;cnicas y m&eacute;todos que permiten lograr la prevenci&oacute;n y el control de plagas."
          >
            Es la acci&oacute;n de eliminar los insectos rastreros y voladores
            mediante tratamientos sanitarios. Todos los procedimientos para
            hacer las aplicaciones se realizar&aacute;n por los equipos
            adecuados, convenientemente regulados y calibrados que se realizan
            conforme sea la formulaci&oacute;n.
            <br /> <br />
            Estos son:
            <ul>
              <li>Termonebulizador</li>
              <li>Nebulizador</li>
              <li>Pulverizador</li>
              <li>Espolvoreador</li>
              <li>
                Aplicaciones con brocha, pincel y pistolas aplicadores en gel
              </li>
            </ul>
            El objetivo principal es conseguir la salud para las personas
            mediante la aplicaci&oacute;n de las medidas de saneamiento,
            adem&aacute;s de pretender lograr el mayor bienestar posible para el
            ser humano y su entorno. Se aplica productos de tipo qu&iacute;mico:
            insecticidas y plaguicidas
          </ServicioSlide>
          <ServicioSlide
            src={m031}
            imgs={[m032, m033, m034]}
            emb={i02}
            title='Desinfecci&oacute;n'
            //subtitle="Reducci&oacute;n de carga microbiana presente en espacios y superficies hasta un 99.99%"
          >
            La desinfecci&oacute;n ambiental es una t&eacute;cnica de
            saneamiento que tiene como finalidad la destrucci&oacute;n de los
            microorganismos pat&oacute;genos (virus, bacterias y hongos),
            encontrados en el ambiente; por lo que en dependencia del agente
            antimicrobiano utilizado, lograremos una desinfecci&oacute;n.
            <br /> <br />
            Es el procedimiento que se utiliza para disminuir la carga
            bacteriana de los objetos supuestamente contaminados para su manejo
            seguro, mediante sustancias de efecto biocida reconocido.
          </ServicioSlide>
          <ServicioSlide
            src={m041}
            imgs={[m042, m043, m044]}
            emb={i05}
            title='Desinfecci&oacute;n de reservorios de agua'>
            Las actividades de limpieza y desinfecci&oacute;n de las cisternas o
            tanques de almacenamiento de agua garantizan almacenar el
            l&iacute;quido en buenas condiciones, siempre y cuando se realicen
            estas actividades peri&oacute;dicamente mediante, la
            utilizaci&oacute;n de las soluciones en las proporciones y procesos
            indicados.
            <br /> <br />
            Que mediante el <strong>D.S N° 031-2010-SA</strong>, se aprueba el{' '}
            <strong>
              “Reglamento de la Calidad del Agua para Consumo Humano”
            </strong>
            , Seg&uacute;n las normas vigentes es aconsejable realizar la
            actividad, limpieza y desinfecci&oacute;n de las cisternas y de los
            tanques elevados dos (2) veces al a&ntilde;o, preferentemente antes
            de la llegada del verano.
            <br /> <br />
            Este proceso, debe efectuarse usando equipos de protecci&oacute;n
            personal (EPP) como: guantes, mascarilla, casco, uniforme.
          </ServicioSlide>
          <ServicioSlide
            src={m051}
            imgs={[m052, m053, m054]}
            emb={i04}
            title='Limpieza de pozo s&eacute;ptico'>
            Limpieza de trampas de grasa y tanques s&eacute;pticos, garantizando
            las actividades de inspecci&oacute;n, limpieza, evacuaci&oacute;n,
            transporte y disposici&oacute;n final de los residuos en los
            rellenos sanitarios autorizados.
          </ServicioSlide>
          <ServicioSlide
            src={m061}
            imgs={[m062, m063, m064]}
            emb={i06}
            title='Limpieza de ambientes'>
            Es la eliminaci&oacute;n de microorganismo y sustancias
            qu&iacute;micas presentes en las superficies. Cualquier proceso de
            limpieza es indispensable realizar el an&aacute;lisis previo de la
            estructura, composici&oacute;n, reparto y tipo de suciedad que se ha
            generado durante una actividad, con lo que de este modo resultara
            más id&oacute;nea la elecci&oacute;n del protocolo de limpieza y el
            detergente que debe ser aplicado.
            <br /> <br />
            Finalmente presentar un ambiente seguro, de los agentes infecciosos
            y sustancias org&aacute;nicas de aquellas superficies en las cuales
            estos pueden encontrar condiciones adecuadas para sobrevivir o
            multiplicarse en dicho ambiente.
          </ServicioSlide>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Servicio;
