import React from 'react';
import '../css/footer.css';

const Footer = () => {
  return (
    <div className='op-footer'>
      {/* <div className="op-bloque">
                <span>Comun&iacute;cate con nosotros y te podremos ayudar. Atendemos los 365 d&iacute;as del año.</span>
            </div> */}
      <div className='op-bloque'>
        <i className='fa fa-whatsapp fa-2x' aria-hidden='true'></i>
        <span>(+51) 947 040 968 / 966 926 894</span>
      </div>
      <div className='op-bloque'>
        <i
          className='fa fa-envelope-o fa-2x'
          aria-hidden='true'
          style={{ fontSize: '27px' }}></i>
        <a href='mailto: informes@emsag-peru.com'>informes@emsag-peru.com</a>
      </div>
      <div className='op-bloque'>
        <i className='fa fa-map-marker fa-2x' aria-hidden='true'></i>
        <span>
          Psj C&eacute;sar Vallejo Mz 64 Lt. 2 AAHH Chill&oacute;n - Los Olivos
        </span>
      </div>
    </div>
  );
};

export default Footer;
