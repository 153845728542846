import React from 'react';

const GaleriaItem = (props) => {
  let id = `#${props.id}`;
  return (
    <>
      <div className='galeria-item'>
        <a href={id}>
          <img src={props.img} alt={props.title} />
        </a>
        <div className='overlay'></div>
        <span className='titulo'>{props.title}</span>
      </div>
    </>
  );
};

export default GaleriaItem;
