import React from 'react';

const ServicioSlide = (props) => {
  const handleClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    let element = e.target;
    let contenedor = element.closest('div.op-section.op-servicios');
    if (
      !element.closest('div.op-section.op-servicios').querySelector('active') &&
      !element.classList.contains('active')
    ) {
      let lista = element
        .closest('div.op-section.op-servicios')
        .querySelectorAll('div.op-slide-serv');
      lista.forEach((item) => {
        item.classList.remove('op-anim-in');
        item.classList.add('op-anim-out');
      });

      element.classList.remove('op-anim-out');
      element.classList.add('active');
    }
    contenedor.classList.add('on');
  };

  const handleClose = (e) => {
    e.stopPropagation();
    e.preventDefault();
    let element = e.target;
    let padre = element.closest('div.op-slide-serv');
    let contenedor = element.closest('div.op-section.op-servicios');
    let lista = contenedor.querySelectorAll('div.op-slide-serv');
    lista.forEach((item) => {
      item.classList.remove('op-anim-out');
      item.classList.add('op-anim-in');
    });
    padre.classList.remove('active');
    contenedor.classList.remove('on');
  };

  let src = props.src;
  //let emb = props.emb;
  let imgs = props.imgs;
  return (
    <div className='op-slide-serv op-anim-in' onClick={(e) => handleClick(e)}>
      <div
        className='op-image'
        style={{ backgroundImage: `url(${src})` }}></div>
      <div className='overlay'></div>
      <div className='content'>
        <h2 className='title'>{props.title}</h2>
        <div className='info'>{props.children}</div>
        <div className='images'>
          {/*<div className="bloque">
            <div
              className="imagen-circular emblema"
              style={{ backgroundImage: `url(${emb})` }}
            ></div>
            <span className="subtitle">{props.subtitle}</span>
          </div>*/}
          <div
            className='imagen-circular imagen-01'
            style={{ backgroundImage: `url(${imgs[0]})` }}></div>
          <div className='bloque'>
            <div
              className='imagen-circular imagen-02'
              style={{ backgroundImage: `url(${imgs[1]})` }}></div>
            <div
              className='imagen-circular imagen-03'
              style={{ backgroundImage: `url(${imgs[2]})` }}></div>
          </div>
        </div>
      </div>
      <div className='btn-close' onClick={(e) => handleClose(e)}></div>
    </div>
  );
};

export default ServicioSlide;
