import React from 'react';

import Footer from './Footer';
import Icono from '../common/Icono';

import i01 from '../assets/Nosostros/nosotros-01.svg';
import i02 from '../assets/Nosostros/nosotros-02.svg';
import i03 from '../assets/Nosostros/nosotros-03.svg';
import i04 from '../assets/Nosostros/nosotros-04.svg';
import i05 from '../assets/Nosostros/nosotros-05.svg';
import i06 from '../assets/Nosostros/nosotros-06.svg';
import i07 from '../assets/Nosostros/nosotros-07.svg';
import i08 from '../assets/Nosostros/nosotros-08.svg';
import i09 from '../assets/Nosostros/nosotros-09.svg';
import i10 from '../assets/Nosostros/nosotros-10.svg';
import i11 from '../assets/Nosostros/nosotros-11.svg';
import i12 from '../assets/Nosostros/nosotros-12.svg';

import '../css/nosotros.css';

const Nosotros = () => {
  return (
    <>
      <div className='op-body'>
        <div className='op-section op-nosotros'>
          <div className='image'></div>
          <div className='op-barra' style={{ paddingBottom: '20px' }}>
            <span className='op-title'>Nosotros</span>
            <p>
              Somos M&#38;P Multiservice SAC con nombre comercial EMSAG
              debidamente registrado con RUC 20602534287, nace para mejorar el
              manejo, tratamiento, en saneamiento ambiental a nivel nacional,
              dedicado a brindar soluciones sostenibles en fumigaci&oacute;n,
              desratizaci&oacute;n, desinfecci&oacute;n de ambientes, limpieza
              de cisternas, succi&oacute;n de pozos s&eacute;pticos, entre otros
              servicios.
              <br />
              <br />
              Nos encontramos comprometidos con la preservaci&oacute;n y cuidado
              del medio ambiente y es por ello que todos los insumos que
              empleamos son biodegradables y se encuentran respaldados por el
              Ministerio de Salud y la OMS.
              <br />
              <br />
              Estamos registrados en el Ministerio de Salud con
              Resoluci&oacute;n de la Direcci&oacute;n de Salud R.A N°068-2019
              DIRIS Lima Norte, acatando las normas legales vigentes, tal como
              lo estipula el D.S. 022-2001-SA y modificatorias.
            </p>
          </div>
          <div className='op-barra subtitle'>
            <div className='op-bloque'>
              <input id='nosotros_vision' type='checkbox'></input>
              <div className='op-bloque-head'>
                <span className='op-subtitle'>Visi&oacute;n</span>
                <label htmlFor='nosotros_vision'>
                  <span></span>
                  <span></span>
                </label>
              </div>
              <div className='op-bloque-content'>
                <p>
                  Brindar soluciones sostenibles en el rubro saneamiento
                  ambiental dirigido al sector p&uacute;blico y privado a nivel
                  nacional, para ello nuestro valor humano est&aacute; en
                  constante desarrollo con capacitaciones y b&uacute;squeda de
                  mejora continua de nuestros procesos administrativos,
                  log&iacute;sticos y operacionales para un &oacute;ptimo
                  desarrollo, y as&iacute; garantizar la calidad de servicios
                  con el uso de tecnolog&iacute;a adecuada y necesaria.
                </p>
              </div>
            </div>
            <div className='op-bloque'>
              <input id='nosotros_mision' type='checkbox'></input>
              <div className='op-bloque-head'>
                <span className='op-subtitle'>Misi&oacute;n</span>
                <label htmlFor='nosotros_mision'>
                  <span></span>
                  <span></span>
                </label>
              </div>
              <div className='op-bloque-content'>
                <p>
                  Ser empresa l&iacute;der a nivel nacional brindando el
                  servicio de saneamiento ambiental, reconocida por su entrega y
                  compromiso, con el medio ambiente y a su vez compitiendo con
                  los m&aacute;s altos &iacute;ndices de calidad.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='op-section'>
          <div className='op-barra icons'>
            <span className='op-subtitle'>Nuestros valores</span>
          </div>
          <div className='op-iconos'>
            <Icono img={i01} title='Integridad' />
            <Icono img={i03} title='Respeto' />
            <Icono img={i05} title='Seguridad' />
            <Icono img={i07} title='Garant&iacute;a' />
            <Icono img={i09} title='Motivaci&oacute;n' />
            <Icono img={i11} title='Trabajo en equipo' />
          </div>
        </div>
        <div className='op-section sectores'>
          <div className='op-barra icons'>
            <span className='op-subtitle'>Principales sectores</span>
            <div>
              Entre los principales sectores que atendemos estan los siguientes
            </div>
          </div>
          <div className='op-iconos'>
            <Icono img={i02} title='INDUSTRIAS' />
            <Icono img={i04} title='ALMACENES' />
            <Icono img={i06} title='MERCADOS' />
            <Icono img={i08} title='CASAS' />
            <Icono img={i10} title='AGROINDUSTRIA' />
            <Icono img={i12} title='MINER&Iacute;A' />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Nosotros;
